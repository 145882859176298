
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmStatus from '@/components/shared/TmStatus.vue'

export default defineComponent({
  components: { TmModal, TmButton, TmFormLine, TmStatus },
  props: {
    errorState: {
      type: Boolean,
    },
  },
  setup() {
    const username = ref('justinmedeiros')
    const firstName = ref('Justin')
    const lastName = ref('Medeiros')
    const mobileNumber = ref('(603) 555-0123')
    const email = ref('justinmedeiros@gmail.com')
    const role = ref('admin')

    return {
      username,
      firstName,
      lastName,
      mobileNumber,
      email,
      role,
    }
  },
})
